export enum QueryKeys {
  BUSINESS_ABOUT = 'business_about',
  BUSINESS_AUTH = 'business_cognito',
  BUSINESS_FAQS = 'business_faqs',
  BUSINESS_IMAGES = 'business_images',
  BUSINESS_LANDING_PAGE_INFO = 'business_landing_page_info',
  BUSINESS_LEGAL = 'business_legal',
  BUSINESS_LOCATIONS = 'business_locations',
  BUSINESS_LOCATIONS_PICKER = 'business_locations_picker',
  BUSINESS_METADATA = 'business_metadata',
  BUSINESS_PLANS = 'business_plan',
  BUSINESS_PRODUCT_ALLERGENS = 'business_product_allergens',
  BUSINESS_PRODUCT_CATEGORIES = 'business_product_categories',
  BUSINESS_PROMOTED_SCHEDULE = 'business_promoted_schedule',
  BUSINESS_SETTINGS = 'business_settings',

  CART = 'cart',

  LOCATION_SCHEDULES_PICKER = 'location_schedules_picker',
  LOCATION_DELIVERIES = 'location_deliveries',
  LOCATION_SCHEDULES = 'location_schedules',

  CHECKOUT = 'checkout',
  CHECKOUT_COMPLETE = 'checkout_complete',

  CONSUMER = 'consumer',
  CONSUMER_PROFILE = 'consumer_profile',
  CONSUMER_ADDRESS = 'consumer_address',
  CONSUMER_DEFAULT_ADDRESS = 'consumer_default_address',
  CONSUMER_ADDRESSES = 'consumer_addresses',
  CONSUMER_ALLERGENS = 'consumer_allergens',
  CONSUMER_CARDS = 'consumer_cards',
  CONSUMER_GIFT_CARDS = 'consumer_gift_cards',
  CONSUMER_CREDIT_BALANCE = 'consumer_credit_balance',
  CONSUMER_PLAN_CHECKOUT = 'consumer_plan_checkout',
  CONSUMER_GIFT_CARD_CHECKOUT = 'consumer_gift_card_checkout',

  GIFT_CARD = 'gift_card',

  ORDER = 'order',
  OFFER = 'offer',

  PLAN = 'plan',
  PLAN_PRODUCTS = 'plan_products',

  RECURRING_ORDER = 'recurring_order',

  SCHEDULE_OFFERS = 'schedule_offers',

  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_INVOICES = 'subscription_invoices',

  TAX = 'tax',

  CONSUMER_ORDERS = 'consumer_orders',
  CONSUMER_ALL_ORDERS = 'consumer_all_orders',
  CONSUMER_SUBSCRIPTIONS = 'consumer_subscriptions',
}

export interface QueryFnProps<InputType, FilterType = void> {
  // TODO: this definition provides basically no type safety.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryKey: [QueryKeys, InputType, FilterType?] | any;
}
